<template>
    <l-map
        id="map"
        ref="myMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :style="`z-index: 0; height: ${mapOptions.height || 640}px;`"
        @ready="initMap()"
        @click="clickOnMap($event)"
    >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker
            v-if="latLng.lat"
            :lat-lng="[Number(latLng.lat), Number(latLng.lng)]"
        >
            <l-icon
                :icon-url="icon"
                :icon-size="dynamicSize"
                :icon-anchor="dynamicAnchor"
            ></l-icon>
        </l-marker>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { CONFIG } from "@/common/config.js";
import icon from "@/assets/marker.png";

import L from "leaflet";

export default {
    name: "BaseMap",

    props: {
        mapOptions: {
            type: Object,
            required: true,
        },
        markerCoords: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
    },

    data() {
        return {
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
            mapObject: null,
            center: CONFIG.map_center,
            zoom: CONFIG.map_zoom,
            latLng: {},
            iconSize: 35,
            icon,

            resizeObserver: null,
        };
    },

    computed: {
        dynamicSize() {
            return [this.iconSize, this.iconSize];
        },

        dynamicAnchor() {
            return [this.iconSize / 2, this.iconSize];
        },
    },

    methods: {
        initMap() {
            const map = this.$refs.myMap.mapObject;
            this.resizeObserver = new ResizeObserver(() => {
                map.invalidateSize();
            });

            this.resizeObserver.observe(this.$el);

            map._onResize();
            this.mapObject = map;
            this.$emit("map-ready")
        },

        clickOnMap(ev) {
            this.latLng = ev.latlng;
            let invalid = ev.latlng.lat ? false : true;
            this.$emit("update:coordinates", ev.latlng);
            this.$emit("update:has-coords", invalid);
        },
    },

    watch: {
        markerCoords: {
            handler(newval) {
                if(!newval || !newval.lat) return
                if (Object.keys(newval).length == 0 || !this.mapObject) return;
                this.latLng = newval;

                const { lat, lng } = newval;
                let marker = [new L.marker([lat, lng])];
                let group = new L.featureGroup(marker);
                this.mapObject.fitBounds(group.getBounds());
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.resizeObserver.unobserve(this.$el);
    },
};
</script>

<style lang="scss" scoped>
#map {
    transition: height 0.3s;
}
</style>
